<template>
  <div>
    <div class="job-info-wrapper" v-if="data">
      <div class="left">
        <div class="info">
          <div class="job-title">
            <div class="title">{{ data.job_title || "职位名称" }}</div>
            <div class="salary">{{ salary || "" }}</div>
          </div>
          <div class="company-name">{{ data.company_name || "公司名称" }}</div>
          <div class="sub-text">
            <span>{{ data.city || "工作地点" }}</span
            ><span class="line">|</span>
            <span>{{ data.education || "学历不限" }}</span
            ><span class="line">|</span>
            <span>{{ data.experience || "经验不限" }}</span>
          </div>
          <div>{{ data.benefits }}</div>
        </div>
        
      </div>

      <div class="right">
        <div class="nums">
          <div>{{ data.required_num ? `招聘人数：${data.required_num}人` : `` }}</div>
          <div style="margin-top:5px">更新于 {{updateTime}}</div>
        </div>
        <!-- <a :href="ats" target="__blank" class="btn">投递</a> -->
        <el-button round class="btn" @click="applyJobs">{{
          user_id ? "投递" : official_open_id ? "前往小程序完善简历":"登录后可投递"
        }}</el-button>
      </div>
    </div>
    <LoginBox :showLogin="toLogin" @showLoginBox="showLoginBox" />
    <WxScanQr :showQr="toWx" @change="showWxBox" />
  </div>
</template>
<script>
import { jobSalaryType, hrefs } from "constants/publicData";
import LoginBox from "components/loginBox";
import WxScanQr from "components/wxScanQr";
import { mapState } from "vuex";
export default {
  name: "JobInfo",
  props: ["data"],
  data: () => ({
    toLogin: false,
    toWx:false,
  }),
  methods: {
    
    showLoginBox(value) {
      this.toLogin = value;
    },
    showWxBox(){
      this.toWx = !this.toWx
    },
    applyJobs() {

      if(!this.official_open_id){
        this.toLogin = true;
        return 
      }
      if (!this.user_id) {
        this.toWx = true
        return 
      }
      

      this.$axiosRequest({
        method: "POST",
        name: "postToApply",
        ids: [this.user_id],
        data: { job_id: this.data.job_id },
        token: true,
      })
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.$notify({
              title: "投递成功",
              message: this.$createElement(
                "i",
                { style: "color: #FF9967" },
                "扫码前往小程序可查看更多该公司的职位"
              ),
            });
            this.applyJobsChannel();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    applyJobsChannel() {
      this.$axiosRequest({
        method: "POST",
        name: "postToChannel",
        ids: [this.user_id],
        data: {
          business_type: 1,
          business_id: this.data.job_id,
          message_content: "我已投递您的职位，期待与您联系",
        },
        token: true,
      })
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    ...mapState({
      user_id: (state) => state.user_id,
      official_open_id: (state) => state.official_open_id,
    }),
    updateTime(){
      if(this.data) return this.$dayjs(this.data.updated_at).format('YYYY/MM/DD')
      else return ''
    },
    ats: () => hrefs.ats,
    salary() {
      let salary_min;
      let salary_max;
      let min_key;
      let max_key;
      let up = false
      if (Number(this.data.salary_min) > 999) {
        salary_min = this.data.salary_min / 1000;
        min_key = true;
      }
      if (Number(this.data.salary_max) > 999) {
        if(Number(this.data.salary_max) == 99999){
          up = true
        }
        salary_max = this.data.salary_max / 1000;
        max_key = true;
      }
      if (salary_min && salary_min > 0) {
        if (salary_max && salary_max > 0) {   
              
          if (min_key && max_key) {
            console.log(salary_min,salary_max);   
            if(up){
              return (salary_min+"K/月 以上 ")
            }else{
              return (
                salary_min + "K-" +
                salary_max + "K"  +
                jobSalaryType[this.data.salary_unit]
              );
            }
          } else {
            return (
              salary_min +
              "-" +
              salary_max +
              jobSalaryType[this.data.salary_unit]
            );
          }
        } else {
          if (min_key) {
            return salary_min + "K" + jobSalaryType[this.data.salary_unit];
          }
          return salary_min + jobSalaryType[this.data.salary_unit];
        }
      } else {
        if (salary_max && salary_max > 0) {
          if (max_key) {
            return salary_max + "K" + jobSalaryType[this.data.salary_unit];
          }
          return salary_max + jobSalaryType[this.data.salary_unit];
        }
      }
      return "面议";
    },
  },
  watch: {},
  components: {
    LoginBox,
    WxScanQr
  },
};
</script>
<style lang="scss">
@import "constants/style/public";
.job-info-wrapper {
  background-color: $defaultColor;
  box-shadow: 0px 0px 18px $shadowColor;
  border-radius: 0px 0px $borderRadius $borderRadius;
  padding: 26px;
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    .job-title {
      display: flex;
      margin-bottom: 26px;
      font-size: $extraExtraLarge;
      .title {
        margin-right: 20px;
      }
      .salary {
        color: $moreColor;
      }
    }
    .company-name,
    .sub-text {
      color: $cardsubTextColor;
      margin-bottom: 16px;
    }
    .sub-text {
      margin-bottom: 16px;
      color: $cardsubTextColor;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    .nums {
      color: $cardsubTextColor;
    }
    .btn {
      min-width: 100px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: $linear;
      color: $defaultColor;
      border-radius: 999px;
      cursor: pointer;
      border: 0;
    }
  }
}
</style>