<template>
  <div class="job-tetail page-container">
    <JobInfoCard :data="data"/>
    <div class="job-introduction" v-if="data">
      <div class="left">
        <Introduction title="职位描述/岗位职责" :content="data.description"/>
        
        <div v-if="data.exam_format">
          <TextHeader title="考试信息" />
          <el-descriptions class="examination-info">
            <el-descriptions-item v-if="data.company_name" label="用工单位">{{data.company_name}}</el-descriptions-item>
            <el-descriptions-item v-if="data.exam_job_category" label="岗位类别">{{data.exam_job_category}}</el-descriptions-item>
            <el-descriptions-item v-if="data.max_age" label="年龄要求">{{data.max_age}}及以下</el-descriptions-item>
            <el-descriptions-item v-if="data.max_age" label="学历要求">{{data.max_age}}及以上</el-descriptions-item>
            <el-descriptions-item v-if="data.major" label="专业要求">{{data.major}}</el-descriptions-item>
            <el-descriptions-item v-if="data.exam_home_requirement" label="户籍要求">{{data.exam_home_requirement}}</el-descriptions-item>
            <el-descriptions-item v-if="data.sex" label="性别要求">{{data.sex}}</el-descriptions-item>
            <el-descriptions-item v-if="data.other" label="其他要求">{{data.other}}</el-descriptions-item>
            <el-descriptions-item v-if="data.exam_format" label="考试形式">{{data.exam_format}}</el-descriptions-item>
          </el-descriptions>
        </div>

        <Introduction title="联系方式" :phone="companyData.phone_number" :email="companyData.email" v-if="data"/>
        <Introduction title="工作地点" :addressMap="city" v-if="data" style="margin-top:40px"/>
        <div>
          <TextHeader title="公司信息" />
          <div>
            <CompanyCard width="100%" v-if="companyData" :data="companyData" :link="toCompanyDetail+data.company_id+(cfId?`/?cf_id=${cfId}`:'')"/>
          </div>
        </div>
      </div>
      <div class="right">
        <Introduction title="进入小程序" :qrUrl="data.company_qr_code_url"/>
      </div>
    </div>
  </div>
</template>
<script>
import JobInfoCard from "components/jobs/detail/jobDetailInfo"
import Introduction from "components/introduction"
import TextHeader from "components/textHeader";
import CompanyCard from "components/companyCard";
import {toCompanyDetail} from "constants/routerLink"
export default {
  name:"JobsDetail",
  data:()=>({
    data:null,
    companyData:null
  }),
  computed:{
    toCompanyDetail:()=>toCompanyDetail,
    city(){
      console.log(this.data.city + this.data.work_address);

      if(this.data){
        if(this.data.city){
          if(this.data.work_address){
            return this.data.city+this.data.work_address
          }else{
            return this.data.city
          }
        }else{
          if(this.data.work_address){
            return this.data.work_address
          }else{
            return ""
          }
        }
      }else{
        return ""
      }
    },
    cfId(){
      return this.$route.query.cf_id?this.$route.query.cf_id:undefined;
    }
  },
  created(){
    this.getData()
  },
  methods:{
    getData() {
      this.$axiosRequest({
        name: "getJobDetail",
        ids:this.$route.params.id,
      })
        .then((res) => {
          if (res.status === 200) {
            this.data = res.data;
            this.getCompanyData(res.data.company_id)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompanyData(id) {
      this.$axiosRequest({
        name: "getCompanyDetail",
        ids:[id],
      })
        .then((res) => {
          if (res.status === 200) {
            this.companyData = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch:{},
  components:{
    JobInfoCard,
    Introduction,
    TextHeader,
    CompanyCard,
  }
}
</script>
<style lang="scss">
@import "constants/style/public";
.job-tetail{
  text-align: left;
  padding-bottom: 60px;
  .job-introduction{
    margin-top: 25px;
    display: flex;
    .left{
      padding-right: 45px;
      width: 75%;
      border-right: 1px solid $lineColor;
    }
    .right{
      width: 25%;
      padding-left: 45px;
    }
  }
  .examination-info{
    margin-left: 15px;
    
  }
  .examination-info-item{
    margin-bottom: 10px;
  }
  .el-descriptions__body{
      background-color:transparent;
  }
}
</style>