<template>
    <div class="compoent-login-box">
    <el-dialog
      title="进入小程序"
      :visible.sync="showQr"
      width="300px"
      :before-close="close"
      center
      append-to-body  
    >
      <div class="login-img-box">
        <div style="width:200px;height:200px;position:relative">
          <el-image
          :style="'width:200px;height:200px;'+(!outQr?'filter: blur(5px);':'')"
          :src="miniprogram_qr_code_url"
         >
          <div slot="error"></div>
          </el-image>
          <div class="zhezhao" @click="getWxQr" v-show="!outQr" :style="outQr?'display:flex;border:2px solid #eee;border-radius: 8px;':''">
            <div class="zhezhao-wapper">
              <span class="icon el-icon-refresh-right"></span>
              <span>点击刷新</span>
            </div>
          </div>
        </div>
         
      </div>
      <span slot="footer">
        <el-button class="login-box-back" @click.stop="close" >返 回</el-button>
        <!-- <el-button type="primary" @click="()=>{$emit('showLoginBox',false)}">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {mapState,mapMutations} from 'vuex'

export default {
  name:"WappQr",
  data:()=>({
    miniprogram_qr_code_url:'',
    outQr:false,
    lxnums:0,
    qrlxTimer:null
  }),
  props:["showQr"],
  created(){
    if(this.official_open_id){
      this.getWxQr(this.official_open_id)
    }
  },
  methods:{
    ...mapMutations(["setOpenId", "setToken", "setUserId","setUserInfo","setPublicQrId"]),
    close(){
      clearTimeout(this.qrlxTimer)
      this.$emit('change')
    },
    //获取小程序二维码
    getWxQr(official_open_id) {
      let scene = `${official_open_id},${this.user_id?1:0}`
      this.$axiosRequest({
        name: "getWxLoginQr",
        params: { scene: scene,page:'pages/resume/resume', source_web: 5 },
      })
        .then((res) => {
          if (res.status === 200) {
            this.miniprogram_qr_code_url = res.data.miniprogram_qr_code_url
            console.log("qr", res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //初始化轮询
    defaultlxNmus(fn) {
      this.lxnums = 2;
      this.outQr = false
      if (fn) {
        fn();
      }
    },
    //开始轮训
    getLx() {
      console.log('startLx');
      this.$axiosRequest({
        name: "lx",
        params: {
          qr_code_url_id: JSON.parse(localStorage.getItem('PublicQrData')).qr_code_url_id,
        },
      })
      .then((res) => {
        this.lxnums -= 1;
        if (res.status === 200) {
          if (res.data.focus_status !== false && res.data.token && res.data.user_id) {
            console.log(res);
            this.setToken(res.data.token); 
            this.setUserId(res.data.user_id);
            this.close()
          } else {
            this.qrlxTimer = setTimeout(() => {
              if (this.lxnums > 0 && this.showQr ) this.getLx();
              else this.outQr = true
            }, 2000);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    },
  },

  computed:{
    ...mapState({
      user_id:state=>state.user_id,
      resume_id:state=>state.userInfo.resume_id,
      official_open_id:state=>state.official_open_id,
    }),
  },
  watch:{
    showQr(val,prev){
      if(val!==prev&&val===true){
        this.defaultlxNmus(this.getLx)
      }
    }
  },

}
</script>
<style lang="scss">
@import "constants/style/public";
.login-img-box{
    display: flex;
    justify-content: center;
    align-content: center;
    .zhezhao{
      position: absolute;
      top:0;
      left: 0;
      width:200px;
      height:200px;
      justify-content: center;
      align-items: center;
      .zhezhao-wapper{
        display: flex;
        align-items: center;
        padding:8px;
        border-radius: 8px;
        background-color: #fff;
      }
      .icon{
        cursor: pointer;
        font-size: 20px;
        margin-right: 3px;
        color: $moreColor;
        
      }   
    }
   
  }
   .login-box-back.el-button:hover{
      background-color: rgba($color: $moreColor, $alpha: 0.1);
      color:$moreColor
    }
</style>